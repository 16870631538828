@include media(lg) {
    .o-header-slider {
        position: relative;
        display: flex;
        width: 100%;
        &::after {
            content: "";
            position: absolute;
            background-image: url(/images/svg/brush-bottom.svg);
            height: 9px;
            display: flex;
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 1;
        }

        &__banner-img {
            aspect-ratio: 3 / 1;
        }

        &__brand-logo-wrapper {
            bottom: $space * 6;
            left: initial;
            top: initial;
            right: 0;
        }

        .o-eye-catcher {
            right: $space * 6;
            top: 0;
            bottom: initial;
        }

        &__remaining-days {
            @include text-variant(small-plus);
            margin-bottom: $space;
        }

        &__campaign-title {
            margin-bottom: $space * 3;
        }

        &__campaign-subtitle {
            @include text-variant(medium);
            margin-bottom: $space * 4;
            @include line-clamp(2);
        }

        &__btn {
            width: 250px;
        }

        &__top-container {
            width: 100%;
        }

        &__bottom-container {
            width: 56.25%;
            padding-bottom: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            aspect-ratio: initial;

            &::before,
            &::after {
                content: initial;
            }
        }

        &__bottom-container-inner {
            text-align: initial;
            padding: 0 20% 0 ($space * 6);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        }
    }
}

;@import "sass-embedded-legacy-load-done:1140";