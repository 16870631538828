/* ------------------------------------ *\
    INFO BOX
\* ------------------------------------ */

.info-box {
    align-items: center;
    background-color: $grey--light;
    box-shadow: $shadow1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: ($space * 3) 0;
    text-align: center;

    &__image {
        margin-bottom: $space * 3;
    }

    &__icon {
        vertical-align: bottom;
        max-height: 140px;
    }

    &--white {
        background-color: #fff;
        box-shadow: $shadow6;
    }

    &--reduced {
        @include border($where: top);
        @include border($where: bottom);

        border-radius: $border-radius;
        padding: $space * 2;
    }
    &--wrapper {
        .info-box__icon {
            max-width: 140px;
        }
    }

    @include media(lg) {
        text-align: left;

        &__icon {
            max-height: 100%;
        }

        &__image {
            margin-bottom: 0;
        }

        &--reduced {
            @include border($where: left);
            @include border($where: right);

            background-color: $grey--light;
            padding: $space * 2;
        }
    }

    &--display-block {
        display: block;
    }
}


;@import "sass-embedded-legacy-load-done:887";