//TODO Deprecated: Remove once all usages are replaced with:
//static/assets/Yves/styles/v2/templates/_panel.template.scss

.panel {
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: $shadow6;
    position: relative;

    &__header,
    &__body,
    &__footer {
        position: relative;
        padding: $space * 3;
    }

    &__header {
        @include border(bottom, $color: $grey--light);

        &--success {
            background-color: $green;
            color: #fff;
        }
    }

    &__header--grey {
        background-color: $grey--lighter;
    }

    &__header--warning {
        background-color: $yellow;
        color: #fff;
    }

    &__footer {
        background-color: $grey--lighter;
        border-radius: $border-radius;
    }

    &__horizontal-break {
        margin: ($space * 2) 0 ($space * 3);
        width: auto;
        border-color: $grey--light;
    }

    &__content--in-modal {
        overflow-y: auto;
        backface-visibility: hidden; //prevents text blurriness in webkit

        h1,
        h2,
        h3 { font-weight: $font-weight--bold; }

        h1,
        h2,
        h3,
        h4,
        h5,
        p { margin-bottom: 1em; }
    }

    &__content--video {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 50%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            z-index: $z1;
        }
    }

    &--in-modal {
        box-shadow: $shadow3;
        cursor: default;
        max-width: 800px;
        width: calc(100% - 30px);
        max-height: calc(100% - 30px);
        overflow-y: auto;
        z-index: $z2;

        .panel__header {
            padding-right: 40px;
        }

        .panel__headline {
            font-weight: $font-weight--bold;
        }

        .panel__header.hidden + .panel__body {
            padding-top: 40px;
        }
    }

    &--payment-method {
        .panel__sub-header {
            background-color: $grey--light;
            font-weight: $font-weight--bold;
            color: $grey--dark;
            padding: ($space * 3) 0;
        }

        .panel__body {
            padding: 0;
        }
    }

    &--small {
        max-width: 400px;
    }
}

;@import "sass-embedded-legacy-load-done:902";