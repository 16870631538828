.product-relations-search {
    display: flex;
    flex-wrap: wrap;

    &__breadcrumb {
        display: none;
    }

    &__sidebar {
        width: 100%;
    }

    &__content {
        width: 100%;
    }

    &__title,
    &__subtitle {
        display: none;
        font-size: $font-size--medium;
    }

    &__title {
        font-family: $font-family--main;
        font-size: $font-size--xlarge;
    }

    &__subtitle {
        margin-bottom: $space * 2;
    }
}

@include media(lg) {
    .product-relations-search {
        &__breadcrumb {
            display: block;
            padding: ($space * 2) 0;
            width: 100%;
        }

        &__sidebar {
            width: percentage(calc(1 / 3));
            padding-right: $space * 2;
        }

        &__content {
            width: percentage(calc(2 / 3));
        }

        &__title,
        &__subtitle {
            display: block;
        }

        &__mobile-switch {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

@include media(xl) {
    .product-relations-search {
        &__sidebar {
            width: percentage(calc(1 / 4));
        }

        &__content {
            width: percentage(calc(3 / 4));
        }
    }
}

;@import "sass-embedded-legacy-load-done:493";