@include media(lg) {
    .cart-v2 .t-cart {
        &__left-container {
            padding: ($space * 8) 0;
        }

        &__items {
            margin-bottom: $space * 8;
        }
    }
}

;@import "sass-embedded-legacy-load-done:810";