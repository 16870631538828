.t-cart {
    &__main-container {
        .t-cart-empty {
            padding: $space * 6 0;
            width: 100%;

            &__icon {
                max-height: 200px;
                margin-bottom: $space * 6;
                align-self: center;
            }

            &__icon-wrapper {
                display: flex;
                justify-content: center;
                margin-bottom: 0;
            }

            &__text {
                text-align: center;
            }

            &__content {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            &__wrapper {
                padding: $space * 4;
            }

            &__button {
                width: 100%;
                margin-top: $space * 8;
                height: 48px;
                font-size: $font-size--regular-plus;
            }

            &__headline {
                margin-bottom: $space * 6;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:852";