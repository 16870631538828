.dropdown {
    .dropdown-content {
        display: none;
    }

    &__arrow-up {
        visibility: hidden;
    }

    &.opened {
        .dropdown-content {
            display: block;
        }
    }

    &.opened & {
        &__arrow-down {
            visibility: hidden;
        }

        &__arrow-up {
            visibility: visible;
        }
    }
}

;@import "sass-embedded-legacy-load-done:946";