@include media(lg) {
    .m-banner {
        &--mobile-only {
            display: none;
        }

        &--lg-only {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:976";