@include media(lg) {
    .o-full-picture-block {
        width: 100%;
        margin: 0 0 $space * 20;
        padding: 0;

        &__text {
            margin: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1096";