@include media(lg) {
    .order-history-search-bar {
        &__search {
            max-width: 400px;
        }
    }

    .order-history__filter {
        padding-left: $space * 20;
    }
}

;@import "sass-embedded-legacy-load-done:728";