.footer-seo {
    line-height: 18px;

    &__headline {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__list {
        padding-right: 5px;
        line-height: 18px;
    }

    &__link {
        color: $grey--darker;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &__link:hover {
        color: $blue;
        text-decoration: none;
    }
}

;@import "sass-embedded-legacy-load-done:279";