.o-product-comparison {
    &__img {
        position: relative;
        display: block;
        margin: 0 auto;
    }

    &__name {
        height: 36px;
        overflow: hidden;
        margin-bottom: $space *2;
    }

    table {
        margin-top: $space * 5;
        margin-bottom: $space * 8;
        @include border($where: 'bottom', $width: 1px, $color: $grey);

        th,
        td {
            @include border($where: 'right', $width: 1px, $color: $grey);
            width: percentage(calc(1 / 4));
        }

        th {
            vertical-align: middle;
        }

        tbody th:nth-child(odd) {
            background-color: transparent;
        }
    }

    &__placeholder {
        display: flex;
        min-height: 628px;
    }
}

;@import "sass-embedded-legacy-load-done:1280";