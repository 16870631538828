@include media(lg) {
    .o-featured-product {
        flex-direction: row;

        &__header {
            font-size: $font-size--xlarge-plus;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $space * 6;
            height: 100%;
        }

        &__button {
            margin-top: $space * 6;
            align-self: flex-start;
        }

        &__image,
        &__content {
            width: 50%;
            height: 481px;
        }

        &__image {
            &--small {
                display: flex;
                align-items: center;

                > * {
                    width: 100%;
                }
            }
        }

        &__content {
            &--large {
                padding: ($space * 6) ($space * 8);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1354";