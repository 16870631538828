.button--password-toggle {
    background-color: transparent;
    color: $theme-color;
    font-size: $font-size--small;
    font-weight: $font-weight--bold;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    border: 0;
    margin-right: $space * 4;
}

;@import "sass-embedded-legacy-load-done:787";