/* ------------------------------------------------------------------------------------ *\
    CHECKOUT PAYMENT ICONS
    CUSTOMER ACCOUNT PAYMENT ICONS
    //TODO: create payment icon components that can be served in 1) Customer account 2) checkout payment methods 3) checkout summary widget component
    Styles to serve for different stores @Checkout/Theme/boss/partials/payment-background-styles.twig
\* ------------------------------------------------------------------------------------ */

.page-checkout-index,
.page-success-success,
.page-v2-index {
    .main-wrapper {
        background-color: $grey--light;
    }

    .header--reduced .icon--trusted-shops {
        width: 30px;
        height: 30px;
    }
}

.payment-image {
    background-position: 10px 4px;
    background-size: 50px 30px;
    background-repeat: no-repeat;
    min-width: 30px;
}

.payment-image[data-payment-type="payment.payone.creditcard_pseudo"] {
    min-width: 165px;
}

input[value="payment.payone.eps"] ~ label,
.payment-image[data-payment-type="payment.payone.eps"],
input[value="payment.stripe.eps"] ~ label,
.payment-image[data-payment-type="payment.stripe.eps"] {
    background-image: url(/images/svg/payment/new/logo-eps.svg);
}

input[value="payment.klarna.invoice"] ~ label,
.payment-image[data-payment-type="payment.klarna.invoice"] {
    background-image: url(/images/svg/payment/new/logo-klarna.svg);
}

input[value="payment.paypal.paypal"] ~ label,
input[value="payment.payone.paypal"] ~ label,
.payment-image[data-payment-type="payment.paypal.paypal"],
.payment-image[data-payment-type="payment.payone.paypal"] {
    background-image: url(/images/svg/payment/new/logo-paypal.svg);

}

input[value="payment.payone.prepayment"] ~ label,
.payment-image[data-payment-type="payment.payone.prepayment"] {
    background-image: url(/images/svg/payment/new/logo-vorkasse.svg);
}

input[value="payment.payone.ideal"] ~ label,
.payment-image[data-payment-type="payment.payone.ideal"] {
    background-image: url(/images/svg/payment/new/logo-ideal.svg);
}

.co-payment {
    background-color: white;
    padding: $space * 2;
    border-radius: $border-radius;

    @include media(lg) {
        padding: unset;
    }

    &__methods {
        z-index: $z1;
    }

    &__labels {
        background-position: 100% 5px;
        background-size: 50px 30px;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        padding-left: 30px;
        height: 40px;
        padding-top: 10px;
    }

    &__details {
        &--disabled {
            color: $grey;
        }

        &--klarna-container {
            max-width: 600px;
        }

        min-width: 100%;
        margin-top: $space * 2;
        padding-top: $space * 2;
    }

    .creditCardTypeIcon {
        position: absolute;
        top: 33px;
        right: $space * 2;

        @include media(lg) {
            top: 24px;
        }
    }

    .form__field {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-flex: 1 0 100%;
        flex: 1 0 100%;

        &--radio {
            @include border(top);
            align-items: flex-start;
            background: transparent 100% ($space * 2) no-repeat;
            background-size: 60px;
            min-width: 100%;
            margin-left: 0;
            padding-top: $space * 2;
            padding-bottom: $space * 2;
        }

        &--radio:first-child {
            border: 0;
            margin-top: 0;
        }

        &--radio input + span {
            top: 10px;
            transform: translateY(0%);
        }

        &--checkbox-default {
            -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
            margin: ($space * 3) 0;
            align-items: flex-start;

            .checkbox-btn {
                top: 0;
                transform: translateY(0%);
            }
        }

        &--checkbox {
            flex-wrap: nowrap;
            flex-basis: calc(100% - 15px);
        }

        &--date {
            align-items: start;
            margin-bottom: $space * 2;
        }

        &--date-label {
            margin: 0;
        }

        &--date-info {
            margin-bottom: $space * 2;
        }
    }

    .payone-label {
        margin: 0;
    }
}

.paymentIcon {
    background: transparent 0 100% no-repeat;
    background-size: 60px;
    padding-bottom: $space * 5;
}

.payment-icon {
    display: inline-block;
    background-repeat: no-repeat;
    width: 50px;
    height: 30px;
    background-size: 50px;

    &--visa {
        background-image: url(/images/svg/payment/new/logo-visa.svg);
    }

    &--mastercard {
        background-image: url(/images/svg/payment/new/logo-mastercard.svg);
    }

    &--amex {
        background-image: url(/images/svg/payment/new/logo-amex.svg);
    }

    &--rechnung {
        background-image: url(/images/svg/payment/new/logo-rechnung.svg);
    }

    &--vorkasse {
        background-image: url(/images/svg/payment/new/logo-vorkasse.svg);
    }

    &--paypal {
        background-image: url(/images/svg/payment/new/logo-paypal.svg);
    }

    &--eps {
        background-image: url(/images/svg/payment/new/logo-eps.svg);
    }

    &--lastschrift {
        background-image: url(/images/svg/payment/new/logo-lastschrift.svg);
    }

    &--facture {
        background-image: url(/images/svg/payment/new/logo-facture.svg);
    }

    &--virement-bancaire {
        background-image: url(/images/svg/payment/new/logo-virement-bancaire.svg);
    }

    &--carte-bancaire {
        background-image: url(/images/svg/payment/new/logo-carte-bancaire.svg);
    }
}

.payone-input {
    border-radius: $border-radius;
    @include border();
    height: 42px;
    padding-right: 1px;

    &.error {
        border: 1px solid $red;
        height: 34px;
    }

    @include media(lg) {
        height: 32px;
    }
}

.payone-select {
    @include border();
    border-radius: $border-radius;
    height: 42px;
    position: relative;
    padding-right: 1px;

    &::after {
        background-color: #fff;
        background-image: url(../../../images/sprite-inputs.png);
        background-position: calc(100%) -17px;
        background-repeat: no-repeat;
        background-size: 16px;
        color: $grey--darker;
        content: '';
        height: 25px;
        pointer-events: none;
        position: absolute;
        right: 8px;
        top: 8px;
        width: 19px;
    }

    &.error {
        border: 1px solid $red;
        height: 34px;
    }

    @include media(lg) {
        height: 32px;

        &::after {
            top: 3px;
        }
    }
}

.checkout-product-item,
.checkout-next-step {
    @include border($where: bottom, $color: $grey--medium);

    &:last-child {
        border: 0;
    }
}

.checkout-total-amount {
    @include border($where: bottom, $color: $grey--medium);
    @include border($where: top, $color: $grey--medium);
}

.security-text {
    font-size: $font-size--small;

    @include media(md) {
        font-size: $font-size--regular;
    }

    @include media(xl) {
        font-size: $font-size--small;
    }

    @include media(xxl) {
        font-size: $font-size--regular;
    }
}

.table-header {
    display: none;

    @include media(lg) {
        display: flex;
    }
}

.steps-contact-block {
    display: block;
    width: 100%;

    @include media(lg) {
        display: flex;
        width: auto;
    }

    &__icon {
        background-color: $grey;
        font-size: 18px;
        line-height: 18px;

        &-success {
            background-color: $green;
        }
    }
}

.delivery-info-icon {
    font-size: $space * 8;
}

.delivery-info-text {
    line-height: $space * 4;
    margin-right: $space * 5;

    @include media(md) {
        line-height: $space * 8;
    }
}

.short-delivery-info-block {
    @include border($where: top, $color: $grey--medium);
}

.checkout-payment-method {
    line-height: $line-height--xlarge;
}

.checkout-password-form {
    box-shadow: $shadow6;

    &__header {
        @include border($where: bottom, $color: $grey--medium);
    }
}

.checkout__notifications {
    &-icon {
        background: $blue;
        border-radius: 50%;
        color: $grey--light;
        font-size: $font-size--xlarge;
        padding: $space * 3;
    }

    &-content {
        padding: $space * 3;
    }

    &-headline {
        padding: $space * 3;
        background: #fff;
        font-weight: $font-weight--bold;
    }

    &-link {
        color: $blue;
        text-decoration: none;
        cursor: pointer;
        display: block;
        font-weight: $font-weight--bold;
    }
}

.checkout__info-icon {
    font-size: $font-size--regular-plus;
    padding-right: $space;
    line-height: $line-height;
}

.checkout__info--creditcard-edit-limit {
    display: flex;
    color: $grey--dark;
    margin-top: $space * 2;
}

/* ------------------------------------ *\
    BOSS-16636 clean up after Accordion Checkout AB test START
\* ------------------------------------ */
.checkout-header {
    background-color: white;
    padding: $space * 2;
    box-shadow: $shadow6;
    z-index: $z2;

    &__icon {
        color: $grey--dark;
        line-height: $line-height--large;
        font-size: $font-size--large;
        padding-top: $space;
        margin-right: $space;
    }

    &__items {
        box-shadow: $shadow6;
        position: fixed;
        z-index: $z3;

        &.collapsed {
            display: none;
        }
    }

    &__items-total {
        font-size: $font-size--regular-plus;
        font-weight: $font-weight--bold;
    }

    &__item-product-name {
        word-break: break-all;
        max-height: 40px;
        overflow: hidden;
    }

    &__item-product-price {
        margin-left: auto;
    }

    &__collapsible-button {
        .collapsed &::before {
            content: $fa-var-angle-down;
        }

        color: $blue;
        font-size: $font-size--icon;
        font-weight: $font-weight--bold;
        padding-top: $space;
        margin-left: auto;
    }

    &__overlay {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($grey--darker, 0.7);
        z-index: $z2;

        &.collapsed {
            display: none;
        }
    }

    &__attributes-packages {
        max-height: $space * 5;
        overflow: hidden;
    }
}

.checkout-accordion {
    margin-bottom: $space * 5;
    .co-payment .form__field--radio {
        @include border(bottom);

        border-top: 0;

        &:last-of-type {
            border: 0;
        }
    }
}

// reset input styles to display billie widget properly
.b-checkout {
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial;

    input[type=checkbox] {
        opacity: 1;
    }

    input[type=text],
    input[type=search] {
        height: auto;
    }

    .btn {
        height: auto;
    }

    .link {
        text-decoration: none;
    }
}

.checkout-form-hint-toggler {
    display: none;
}

.o-payment-panel__option:disabled {
    &:hover + span {
        border-color: $grey;
        cursor: none;
    }
    & + span + label {
        color: $grey--dark;
    }
}

;@import "sass-embedded-legacy-load-done:591";