.o-expandable-product-list {
    &__products {
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }

    &__product-container {
        display: block;
        transform: translateY(1.5px);
        padding: ($space * 2) 0 ($space * 4) 0;
        border-bottom: 1px solid $grey--medium;
        &.-md {
            display: none;
        }

        & > div:nth-child(n) {
            padding: 0;
        }
    }

    &__product-container:nth-child(n + 8) {
        display: none;
    }

    &__product-container.-expanded:nth-child(2n) {
        display: block;
    }

    &__cta {
        display: flex;
        justify-content: center;
    }

    &__cta.-show-more {
        border-bottom: 1px solid $grey--medium;
        margin-top: $space * 8;
        padding-bottom: $space * 14;
        margin-bottom: $space * 14;
    }

    &__cta.-show-all {
        border-top: 1px solid $grey--medium;
        display: none;
        margin-top: $space * 8;
        padding-top: $space * 14;
        margin-bottom: $space * 14;
    }

    &__cta.-show-all.-expanded {
        display: flex;
    }

    &__cta.-show-more.-expanded {
        display: none;
    }

    &__show-more,
    &__show-all {
        min-width: 160px;
        width: 100%;
        padding: (3 * $space) (4 * $space);
        height: 48px;
    }
}

;@import "sass-embedded-legacy-load-done:1157";