.o-product-teaser {
    margin-bottom: $space * 14;

    &--wrap {
        flex-wrap: wrap;
    }

    &__header h2 {
        font-size: $font-size--xlarge;
        line-height: $line-height--xlarge;
        margin: 0 0 ($space * 8);
        text-align: center;

    }

    &__list {
        width: calc(100% - 10px);
    }

    &__list > li:last-child {
        margin-bottom: $space;
    }

    &__element {
        min-width: 175px;
        margin: 0 ($space * 2) ($space * 4) 0;
        box-shadow: $shadow9;
        width: calc(33.3% - 10px);

        &.m-infospace {
            background-color: transparent;
        }

        .m-infospace {
            &__content {
                margin: ($space * 6) ($space * 4);
            }

            &__link {
                font-size: $font-size--medium;
                line-height: $line-height--list;
                color: $base-text-color;
                margin-top: 0;
            }

            &__link:hover {
                color: $theme-color;
                text-decoration: none;
            }

            &__image {
                object-fit: contain;
                max-height: 207px;
                width: 100%;
                background-color: white;
            }

            &__image--wrapper {
                height: 207px;
            }
        }
    }

    &--row {
        display: flex;
        flex-wrap: nowrap;
        flex: 1;
        width: 100%;
    }

    .m-infospace {
        padding: 0;
    }

    .m-menu-item {
        width: 100%;
        padding-left: 0;

        &__text {
            font-weight: $font-weight--bold;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1362";