.menu-header-dropdown {
    border-radius: $border-radius;
    box-shadow: $shadow3;
    color: $base-text-color;
    display: none;
    left: 50%;
    max-width: 230px;
    min-width: 230px;
    position: absolute;
    top: calc(100% + 15px);
    transform: translateX(-50%);

    .hover & {
        display: block;
    }

    &__header {
        @include border($where: bottom, $color: $grey--light);
        padding: ($space * 4) 0 ($space * 4);

        h3 {
            margin-left: $space * 3;
            width: calc(100% - 30px);
        }
    }

    &__list-item {
        .fa,
        [class^="icon--"] {
            width: 50px;
        }

        .fa {
            font-size: $font-size--medium-plus;
        }

        [class^="icon--"] {
            font-size: $font-size--medium;
        }

        &--without-icon {
            &::after {
                border: 0;
            }

            a:hover {
                background-color: #fff;
                color: $blue;
            }

            a {
                font-weight: $font-weight--base;
                padding: 0 0 0 ($space * 10);
            }
        }

        & + &--without-icon {
            padding-top: $space * 2;
        }

        &--without-icon + & {
            margin-top: $space * 2;

            &::before {
                @include border(top, $color: $grey--light);
                content: "";
                left: 40px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: $z1;
            }
        }

        &::after {
            left: 50px;
        }
    }

    &::before {
        border-bottom: 15px solid #fff;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        bottom: calc(100% - 5px);
        content: "";
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 0;
    }

    &::after {
        background: transparent;
        content: "";
        height: 23px;
        left: 0;
        position: absolute;
        top: -23px;
        width: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:890";