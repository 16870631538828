@include media(xxl) {
    .m-product-list-item {
        &__title {
            height: 2 * $line-height--regular;
            overflow: hidden;
        }
        &__subtitle {
            height: $font-size--regular-plus;
            overflow: hidden;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1220";