.m-product-list-empty {
    display: flex;
    flex-direction: column;
    row-gap: 3 * $space;
    align-items: center;
    text-align: center;

    &__action-button {
        margin-top: 3 * $space;
        width: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:1025";