@include media(lg) {
    .o-cart-items {
        &__action {
            height: max-content;

            .button {
                height: auto;
            }
        }
        &__action:nth-child(2) {
            padding-left: $space * 6;
            margin-left: $space * 6;
            border-left: 1px solid #ccc;
        }
    }
}

;@import "sass-embedded-legacy-load-done:872";