.m-anchor-links__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;

    .m-anchor-links__list-container {
        display: inline-flex;
        flex-wrap: nowrap;
        gap: 4 * $space;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }

        &.drag-mode,
        &.drag-mode .m-anchor-link {
            cursor: grabbing;
            user-select: none;
        }

        .m-anchor-link {
            @include border($color: white, $width: 1px, $style: solid);
            padding: 2 * $space 3 * $space;
            border-radius: 20px;
            box-sizing: border-box;
            background-color: transparent;
            line-height: $line-height--regular;
            color: white;
            cursor: pointer;
            flex: none;

            &:hover {
                background-color: white;
                color: $grey--darker;
                text-decoration: none;
            }
        }
    }

    .m-anchor-links__arrow-button-container {
        position: absolute;
        width: 60px;

        &.left {
            background: linear-gradient(270deg, rgba(44, 44, 44, 0) 0%, #2c2c2c 100%);
        }

        &.right {
            right: 0;
            direction: rtl;
            background: linear-gradient(90deg, rgba(44, 44, 44, 0) 0%, #2c2c2c 100%);
        }

        .m-anchor-links__arrow-button {
            width: 40px;
            height: 40px;
            border: 1px solid transparent;
            background-color: transparent;
            color: white;
            flex: none;

            &:hover {
                background-color: white;
                color: $grey--darker;
            }
        }

    }

    .m-anchor-links__arrow-button-container,
    .m-anchor-links__arrow-button {
        display: none;
        visibility: visible;
        opacity: 1;
        transition: opacity 1s, visibility 0.5s;

        &.ease-hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

}

;@import "sass-embedded-legacy-load-done:966";