.related-products {
    background-color: white;
    padding: 0;
    margin-top: $space * 2;

    .panel__header {
        display: flex;
        justify-content: space-between;
        border: 0;
        padding: $space * 3;
    }

    .panel__body {
        border: 0;
    }

    &__title {
        padding: $space * 3;
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__list-item {
        @include border(bottom);
        display: flex;
        flex-direction: row;
        margin-top: $space;
        padding: $space;
        position: relative;

        .badges {
            position: absolute;
            top: 0;
            left: 0;
        }

        form {
            margin-top: $space * 2;
        }
    }

    &__list-item-image {
        flex: none;
        margin-right: $space * 2;
        width: 80px;
    }

    &__list-item-title {
        font-weight: $font-weight--bold;
        display: block;
        max-height: $space * 8;
        overflow: hidden;
    }

    &__widget-item {
        min-width: 200px;
        padding: $space * 2;
        position: relative;

        &-badges {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}

@include media(lg) {
    .related-products {
        margin-top: 0;

        &__widget-item {
            min-width: unset;
        }

        .panel__header {
            background: none;
        }

        &__list {
            flex-direction: row;
            padding: $space * 2;
        }

        &__list-item {
            flex-direction: column;
            align-items: center;
            border: 0;
        }

        &__list-item-title {
            min-height: 40px;
        }

        &__list-item-image {
            margin: 0 ($space * 2);
            width: 130px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:910";