.product-configurator {
    &__list-header {
        display: none;
    }

    &__list-item {
        @include border($where: top);
        align-items: center;
        display: none;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: $space * 3;
        padding-top: $space * 3;
    }

    &__product-details {
        margin-bottom: $space * 2;
        width: 100%;
    }

    &__package-size {
        margin-bottom: $space * 2;
        width: 100%;
    }

    &__price {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__add-to-cart {
        margin-top: $space * 2;
        width: 100%;
    }

    &__active-facets {
        display: none;
        padding: $space * 2;
    }

    &__technical-data {
        padding: $space * 2;

        &-title {
            margin-bottom: $space * 2;

            a {
                color: $grey--darker;
                font-weight: $font-weight--bold;
            }

            a:hover {
                color: $theme-color;
                text-decoration: none;
            }
        }
    }

    &__technical-data.collapsed {
        display: none;
    }

    &__tag {
        @include border($color: $blue);
        align-items: center;
        border-radius: $border-radius;
        color: $blue;
        display: flex;
        height: 26px;
        margin-right: $space;
        margin-top: $space;
        padding: $space;
        white-space: nowrap;
    }

    &__list-attributes--header {
        word-break: break-word;
        font-weight: $font-weight--bold;
        margin-bottom: $space *  2;
    }

    &__list-item--col {
        display: flex;
        align-items: center;
        padding-right: $space * 2;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__list-item-sku--chevron {
        padding-right: $space * 2;
    }

    &__list-item-sku {
        font-weight: $font-weight--bold;
    }

    &__list-item-package-size {
        max-width: 150px;
    }

    &__list-attributes,
    &__list-attributes--header {
        display: flex;
        width: 65%;
    }

    &__list-item-technical-data,
    &__list-item-technical-data--header {
        width: 12.5%;
    }

    &__list-item-technical-data {
        padding: 0 ($space * 2);
    }

    &__list-item-technical-data button {
        width: 100%;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__list-item-sku,
    &__list-item-sku--header {
        width: 7.5%;
    }

    &__list-item-buy,
    &__list-item-price--header {
        width: 15%;
    }

    &__list-header {
        transition: 0.2s;
        padding-top: $space * 2;

        &--stuck {
            background: white;
            box-shadow: $shadow-bottom-only;
            position: sticky;
            top: 0;
            z-index: $z5;
        }
    }

    &__mobile-list-item {
        @include border($where: bottom, $color: $grey);
        display: flex;
        flex-wrap: wrap;
        padding: $space * 2 0;
    }

    &__mobile-list-item-name {
        color: $grey--darker;
        font-size: $font-size--medium;
        font-weight: $font-weight--bold;
        padding: ($space * 2) 0;
        width: 100%;
    }

    &__mobile-list-item-sku {
        color: $grey--dark;
        font-size: $font-size--regular;
        font-weight: $font-weight--base;
        width: 100%;
    }

    &__mobile-list-item-delivery-time {
        color: $green;
        font-size: $font-size--regular;
        font-weight: $font-weight--bold;
        margin-bottom: $space * 2;
        width: 100%;
    }

    &__mobile-list-attributes {
        margin-bottom: $space * 4;
        width: 100%;

        td {
            padding: $space * 2;
        }

        tr.collapsed {
            display: none;
        }

        tr:nth-child(odd) {
            background-color: $grey--light;

            td {
                width: 50%;
            }

            td:first-child {
                border-right: 2px solid #fff;
            }
        }
    }

    &__mobile-list-item-package-size {
        width: 100%;
        padding-bottom: $space * 2;
    }

    &__mobile-list-item-technical-data {
        padding: 0 0 ($space * 2) 0;
        width: 100%;

        button {
            width: 100%;
        }

        .button__inner span::before {
            font-size: 22px;
            font-weight: $font-weight--bold;
            left: 5px;
        }

        .button-content-more {
            display: none;
        }

        .button-content-less {
            display: block;
        }

        .collapsed {
            .button-content-more {
                display: block;
            }

            .button-content-less {
                display: none;
            }

            .button__inner span::before {
                left: 0;
                transform: rotate(180deg);
                top: 4px;
            }
        }
    }

    &__mobile-list-item-price {
        width: percentage(calc(1 / 3));
        padding: ($space * 2) ($space * 2) 0 0;
    }

    &__mobile-list-item-buy {
        width: percentage((calc(1 / 3) * 2));
        padding-top: ($space * 2);
    }

    &__mobile-list-content {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &__mobile-list-header {
        display: flex;
    }

    &__mobile-item-image {
        max-width: 70px;
        max-height: 70px;
        margin-right: $space * 3;
    }
    &__mobile-list-item-price-wrapper {
        display: flex;
        flex-direction: row;
    }
}

@include media(md) {
    .product-configurator {
        &__add-to-cart {
            width: percentage(calc(1 / 2));
        }
    }
}

@include media(lg) {
    .product-configurator {
        &__mobile-list-content {
            display: flex;
            width: 100%;
            flex-direction: row;
        }
        &__product-details,
        &__price {
            width: percentage(calc(1 / 4));
        }

        &__package-size {
            text-align: center;
            width: percentage(calc(1 / 6));
        }

        &__add-to-cart {
            margin-top: 0;
            width: percentage(calc(3 / 8));
        }

        &__mobile-list-item-price,
        &__mobile-list-item-buy {
            width: 100%;
        }

        &__mobile-list-item-price-wrapper {
            flex-direction: column;
        }
    }
}

@include media(xl) {
    .product-configurator {
        &__list-header {
            display: flex;
        }

        &__list-item {
            display: flex;
        }

        &__mobile-list-item {
            display: none;
        }

        &__product-details {
            width: percentage(calc(3 / 8));
        }

        &__price {
            width: percentage(calc(5 / 24));
        }

        &__add-to-cart {
            width: percentage(calc(1 / 6));
        }
    }
}

@mixin list-item-columns {
    @for $i from 1 through 8 {
        .product-configurator__list-item--col-#{$i} {
            width: percentage(calc(1 / $i));
        }
    }
}

@include list-item-columns;

;@import "sass-embedded-legacy-load-done:508";