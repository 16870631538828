.m-coupon-info {
    @include border(bottom);
    margin: -5px -15px;
    padding: 0 $space * 3;
    padding-bottom: $space * 2;
    margin-bottom: $space * 2;

    &--text {
        font-weight: $font-weight--bold;
        padding-top: $space * 2;
        display: flex;
        flex-wrap: wrap;
    }

    &--voucher {
        margin-right: $space * 2;
        margin-bottom: $space;
    }
}
;@import "sass-embedded-legacy-load-done:1000";