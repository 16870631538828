@include media(lg) {
    .m-item-prices {
        margin-bottom: auto;
        text-align: right;

        &__wrapper {
            display: flex;
            white-space: nowrap;
            align-items: center;
            justify-content: flex-end;
            gap: 5px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:820";