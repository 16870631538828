.faq-section {
    padding: 2 * $space;

    &.collapsed {
        display: none;
    }

    &__element {

        &--not-first {
            margin-top: 2 * $space;
            padding-top: 2 * $space;

            @include border(top);
        }
    }

    &__question {
        font-weight: $font-weight--bold;
        position: relative;
        display: block;

        & .fa {
            margin-top: 6px;
        }

        & .fa-angle-down {
            display: none;
        }

        &.collapsed {
            font-weight: $font-weight--base;

            & .fa-angle-down {
                display: inline;
            }

            & .fa-angle-up {
                display: none;
            }
        }
    }

    &__answer {
        margin-top: 2 * $space;

        &.collapsed {
            display: none;
        }

        p {
            margin-top: 10px;
        }
    }
}

.faq-menu {
    &__item {
        background-color: $grey--light;
        box-shadow: $shadow1;
        padding: 2 * $space;
        display: block;
        position: relative;
        @include border(top);

        .fa-plus,
        .fa-minus {
            right: 10px;
        }

        .fa-plus {
            display: none;
        }

        &.collapsed {
            box-shadow: none;

            .fa-plus {
                display: block;
            }

            .fa-minus {
                display: none;
            }
        }
    }

    @include media(lg) {
        border-radius: $border-radius;

        &__item {
            @include border($where: left, $width: 3px, $color: $theme-color);
            box-shadow: none;
            padding-left: 7px;
            background-color: #fff;
            font-weight: $font-weight--bold;

            &:hover {
                border-left-color: $theme-color;
                color: inherit;
            }

            &.collapsed {
                border-left-color: transparent;
                font-weight: $font-weight--base;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:885";