.a-thumbnail {
    max-width: 110px;
    max-height: 95px;
    margin: $space * 3;
    cursor: pointer;
    border-bottom: 5px solid transparent;

    &--video {
        width: 110px;
        height: 90px;
        margin: $space * 3;
        padding: $space;
        cursor: pointer;
    }

    &.active,
    &--video.active {
        border-bottom: 5px solid $blue;
    }
}

;@import "sass-embedded-legacy-load-done:1182";