/* ------------------------------------ *\
    CUSTOMER ACCOUNT MENU
\* ------------------------------------ */
.menu--customer-account {
    box-shadow: none;
    margin-left: -($space * 2);
    margin-right: -($space * 2);
    overflow: hidden;
    position: relative;

    .menu__header {
        @include border(bottom);
        min-height: 60px;
        padding: ($space) ($space * 4);
        position: relative;

        &::after {
            color: $blue;
            content: $fa-var-chevron-up;
            font-family: "FontAwesome";
            font-size: $font-size--medium;
            position: absolute;
            right: $space * 3;
            top: 50%;
            transform: translateY(-50%);
        }

        &.collapsed::after {
            content: $fa-var-chevron-down;
        }
    }

    .menu__header-company-name {
        font-size: $font-size--regular;
    }

    .menu__header-user-name {
        font-size: $font-size--regular;
        margin-top: $space;
    }

    .menu__header-logged-in-user {
        font-weight: $font-weight--bold;
    }

    .menu__header-customer-info {
        padding: ($space * 3) ($space *3) 0;
        list-style: none;
    }

    .menu__header-company-name,
    .menu__header-user-name {
        .with-image & {

            margin-left: $space * 4;
        }
    }

    .menu__header-img {
        border-radius: $border-radius;
        box-shadow: $shadow2;
        max-height: 100px;
    }

    .menu__list-item {
        a {
            padding: $space * 3;
            padding-left: 12px; //TODO: Check this with UX to convert to 10/15px

            svg {
                margin-right: 12px; //TODO: Check this with UX to convert to 10/15px
            }
        }

        [class^="icon--"] {
            font-size: 16px;
        }

        .fa {
            font-size: 20px;
        }
    }

    .menu__footer {
        @include border('top');
        padding: $space * 4;

        .image-mask {
            border-radius: 100%;
            overflow: hidden;
            width: percentage(calc(4 / 5));
        }

        p {
            margin: 0 ($space) 0 0;
        }

        &.collapsed {
            border: 0;
            max-height: 0;
            overflow: hidden;
            padding: 0;
        }
    }

    .menu__footer-img {
        max-height: 80px;
    }

    .menu__footer-phone {
        color: $base-text-color;
        margin-top: ($space);
    }

    .menu__footer-title {
        font-size: $font-size--small;
    }

    &.menu--overview {
        margin-top: 0;
    }
}

@include media(lg) {
    .menu--customer-account {
        box-shadow: $shadow1;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 0;

        .menu__header {
            padding: ($space * 2) ($space * 3);

            &::after {
                display: none;
            }
        }

        .menu__footer {
            &.collapsed {
                @include border('top');
                max-height: 600px;
                padding: $space * 4;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:661";